
import DxLoadIndicator from "devextreme-vue/load-indicator";
import DxForm, {
  DxItem,
  DxRequiredRule,
  DxLabel,
  DxButtonItem,
  DxButtonOptions,
} from "devextreme-vue/form";
import notify from 'devextreme/ui/notify';
import auth from "../auth";
import LoginService from "../services/LoginService";
import Cookies from "js-cookie";
window.Cookies = Cookies;
export default {
  name: "Login",
  props: ["user"],
  data() {
    return {
      formData: {},
      loading: false,
      loginpost: [],
    };
  },
  loginservice: null,
  created() {
    this.loginservice = new LoginService();
  },
  mounted() {
    /** 
    this.userdata = Cookies.get("user");
    if (this.userdata == null || this.userdata == 'null') {
      this.$router.push({ path: '/login' });
      this.layoutMode = 'static';
    }
    else {
      this.layoutMode = 'overlay';
      this.$router.replace({ name: "/home" });
      this.$router.push({ path: '/home' });
    }
    */
  },
  methods: {
    onCreateAccountClick() {
      this.$router.push("/create-account");
    },
    onSubmit: async function () {

      const { usuario, password } = this.formData;
      this.loginpost.push({
        pclave: usuario,
        pcontrasena: password,
      });

      this.loginservice.loginUsuarios(this.loginpost[0]).then((data) => {
        this.responselog = data;

        if (this.responselog.err == 0) {


          this.loginpost = [];
          let myJSON = JSON.stringify({
            id: this.responselog.id,
            nombre: this.responselog.nombre,
            correo: this.responselog.correo,
            clienteid: this.responselog.clienteid,
            requierereinicio: this.responselog.requierereinicio,
          });
          console.log('LOGIN JSON',myJSON);
          Cookies.set("ets_user_ec", myJSON);


          auth.logIn(this.responselog.nombre);
         console.log('REQUIERE REINICIO',this.responselog.requierereinicio);
          if(this.responselog.requierereinicio=='1' || this.responselog.requierereinicio==1){

            console.log('RRRRRRRRRRRRR');
            this.$router.push({ path: '/' })
            this.$router.push({ name: 'cambiocontrasena' })
          }
          else{
            console.log('TTTTTTTTTTTTTTTTTTT');
            this.$router.push({ path: '/' })
            this.$router.push({ name: 'home' })
          }
          



        } else {

          this.loading = false;
          notify(this.responselog.msg, "error", 2000);
          this.loginpost = [];
          Cookies.set("ets_user_ec", "null");
        }
      });
    },
  },
  components: {
    DxLoadIndicator,
    DxForm,

    DxRequiredRule,
    DxItem,
    DxLabel,
    DxButtonItem,
    DxButtonOptions,
  },
};
